import IMask from 'imask'
import maskTemplatesMixin from '@/mixins/maskTemplatesMixin.js'

/*
Компонент использующий этот mixin для IMask должен иметь:
- html элемент со свойством ref="[inputRefName]"
- inputType (prop) со свойством editor: 'InputType' (editor-input-type)
- onInput функцию где есть присвоение значения ввода html элемента, например: this.localValue = this.imask.value
Пример в a-string.vue

Руководство imaskjs библиотеки: https://imask.js.org/guide.html
*/

export default {
  mixins: [maskTemplatesMixin],
  data () {
    return {
      imask: null
    }
  },
  computed: {
    // v-mask library
    vmask () {
      // string is old format
      if (this.mask) {
        return this.mask
      }

      if (!this.inputType || this.inputType.mode !== 'maskSymbolsVmask') {
        return
      }

      // object is new format
      if (this.inputType) {
        return this.inputType.maskSymbolsVmask
      }
    },
    isImask () {
      return this.inputType &&
        ['maskSymbolsImask', 'maskTemplateImask'].includes(this.inputType.mode) &&
        (this.inputType.type || this.inputType.isAdvanced) // Выбран "Тип поля" или выбран "Расширенный"
    },
    imaskOptions () {
      const lazy = (this.inputType) ? !this.inputType.isMaskGuide : false
      let maskOptions = {
        mask: '',
        lazy
      }

      if (!this.inputType) {
        return maskOptions
      }

      if (this.inputType.mode === 'maskSymbolsImask') {
        maskOptions.mask = this.inputType.maskSymbolsImask || ''
      }
      if (this.inputType.mode === 'maskTemplateImask') {
        // IMask options via property name in maskTemplates (string) maskTemplatesMixin.js
        const maskTemplate = this.maskTemplates[this.inputType.maskTemplateImask]

        // Copy
        maskOptions = (maskTemplate) ? { ...maskTemplate } : maskOptions
        if (Array.isArray(maskOptions.mask)) {
          // Copy mask array objects (mask can be array of masks)
          maskOptions.mask = maskOptions.mask.map(maskOption => ({ ...maskOption }))
          // maskOptions.mask = JSON.parse(JSON.stringify(maskOptions.mask))
        }

        // Handle
        if (Array.isArray(maskOptions.mask)) {
          maskOptions.mask.map(maskOption => {
            if (maskOption.lazy == null) {
              maskOption.lazy = lazy
            }
            return maskOption
          })
        } else {
          if (maskOptions.lazy == null) {
            maskOptions.lazy = lazy
          }
        }
      }

      return maskOptions
    }
  },
  watch: {
    inputType: {
      handler () {
        // For apply changes of imaskOptions
        this.updateIMaskOptions()
      },
      deep: true
    }
  },
  mounted () {
    if (this.isImask) {
      // IMask
      this.initIMask()
    }
  },
  methods: {
    /**
     * initIMask may fail to initialize because there is no element with ref="[inputRefName]"
     * @param {string} inputRefName
     */
    initIMask (inputRefName = 'input') {
      // path to input inside el-input
      this.maskInputElement = this.$refs[inputRefName]?.$el?.children[0] || this.$refs[inputRefName]?.$el || this.$refs[inputRefName]
      if (this.maskInputElement) {
        this.imask = IMask(this.maskInputElement, this.imaskOptions)
        this.imask.on('accept', () => this.onMaskInputElementAccept())
        this.maskInputElement.addEventListener('input', this.onMaskInputElementInput)
        this.imask.isValid = this.isMaskValueValid()
      } else {
        console.log('cant find maskInputElement for IMask')
      }
    },
    destroyIMask () {
      if (this.imask) {
        this.imask.destroy()
        this.imask = null
        this.maskInputElement.removeEventListener('input', this.onMaskInputElementInput)
      }
    },
    updateIMaskOptions () {
      if (this.isImask) {
        // IMask
        if (!this.imask) {
          this.initIMask()
        }
      } else {
        // Not IMask
        if (this.imask) {
          this.destroyIMask()
        }
        return
      }

      // initIMask may fail to initialize because there is no element with ref="[inputRefName]"
      if (!this.imask) {
        return
      }

      this.imask.updateOptions(this.imaskOptions)
    },
    onMaskInputElementAccept () {
      this.imask.isValid = this.isMaskValueValid()
    },
    onMaskInputElementInput () {
      if (this.imask && typeof this.onInput === 'function') {
        /*
          Firefox и Chrome работают по разному с событиями:
          Firefox - imask не подменяет значение поля ввода своим маскированным значением, если ввод достиг конца маски
            - Поэтому в конце текста, после маски, можно писать символы

          Chrome - imask не вызывает событие accept, если значение в html input совпадает с "последним" маскированным значением
            - "Последнее" маскированное значение в imask не корректно работает в Chrome
            -- В случае <el-input ref="input" v-model="localValue" @input="..."></el-input> и одновременным imask = IMask(this.$refs['input']?.$el?.children[0], ...)
            -- и mask.on('accept', Не сработает)
            -- Функция updateControl https://github.com/uNmAnNeR/imaskjs/blob/master/packages/imask/src/controls/input.js

          Поэтому для Chrome localValue в компоненте сделан через v-model
          И для Firefox заменяем localValue компоненте в onInput маскированным значением .addEventListener('input'..., чтобы не вводило символы в конце маски

          Можно обновить "последнее" маскированное значение с помощью mask.updateValue и пробовать обновить поле ввода через:
          - mask.updateControl()
          - или localValue = this.mask.value
          Но тогда в Firefox будет съезжать позиция курсора и при вводе мерцать в конце с дублированием ранее введённых символов
          Либо в Chrome будут дублироваться символы
          В обоих случаях проблемы
        */

        // Update localValue
        this.onInput(this.imask.value)
      }
    },
    isMaskValueValid () {
      if (!this.imask || !this.imask.masked) {
        return false
      }

      let blocks = this.imask.masked._blocks
      if (!blocks && this.imask.masked.currentMask) {
        // mask is array of masks
        blocks = this.imask.masked.currentMask._blocks
      }

      if (!blocks) {
        return false
      }

      return blocks.filter(block => block.masked).every(block => {
        const masked = block.masked
        const charMask = masked.mask
        const char = masked.value
        const charIsValid = (!block.isOptional) ? charMask.test(char) : true

        return charIsValid
      })
    }
  }
}
